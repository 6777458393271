<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.menu.administration.vaults')"
    canCreate
    :createToolTip="
      $t('global.header.new', [$t('global.concepts.sysvault').toLowerCase()])
    "
    @newItem="create"
  >
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      no-pagination
      :height="tableHeight(85, items?.length)"
      @row-clicked="onRowClick"
    />
  </module-template>
</template>

<script>
import { get } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import { tableHeight } from "@/model/util";

export default {
  name: "VaultList",
  components: {
    OolibaBasicTable,
    ModuleTemplate,
  },
  data() {
    return {
      headers: [{ text: this.$t("global.concepts.name"), value: "name" }],
      items: [],
      vaults: [],

      busy: true,
    };
  },

  methods: {
    tableHeight,

    create() {
      this.$router.push({ name: "Create vault" });
    },
    async getVaults() {
      return await get("/vault")
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.$store.commit("showError", error);
        });
    },
    onRowClick(record) {
      const vaultId = record.id;

      this.$router.push({ name: "Vault", params: { vaultId: vaultId } });
    },
  },
  async created() {
    this.vaults = await this.getVaults();

    if (this.vaults) {
      this.vaults.forEach((value) => {
        const item = {};

        item["id"] = value.id;
        item[this.headers[0].value] = value.name;

        this.items.push(item);
      });
      this.busy = false;
    }
  },
};
</script>
