<template>
  <div>
    <v-tabs class="ooliba-tab" active-class="ooliba-tab-active">
      <v-tab>{{ $t("global.business-process.run-info") }}</v-tab>
      <v-tab-item>
        <ooliba-basic-table
          :headers="headers"
          :items="items?.length > 0 ? [items[0]] : items"
          :loading="busy"
          no-pagination
          @row-clicked="onRowClick"
        >
          <template #[`item.createdOn`]="{ item }">
            {{ formatDate(item.createdOn) }}
          </template>
        </ooliba-basic-table>
      </v-tab-item>

      <v-tab>{{ $t("global.business-process.run-history") }}</v-tab>
      <v-tab-item>
        <ooliba-basic-table
          :headers="headers"
          :items="items"
          :loading="busy"
          no-pagination
          @row-clicked="onRowClick"
        >
          <template #[`item.createdOn`]="{ item }">
            {{ formatDate(item.createdOn) }}
          </template>
        </ooliba-basic-table>
      </v-tab-item>
    </v-tabs>
    <div ref="bottomEl"></div>
  </div>
</template>

<script>
import { get } from "@/model/api";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import { formatDate } from "@/model/util";

export default {
  name: "TaskRunList",
  props: { flowTaskId: Number },
  components: { OolibaBasicTable },
  data() {
    return {
      runs: [],
      headers: [
        { text: "Id", value: "id" },
        { text: this.$t("global.concepts.name"), value: "title" },
        { text: this.$t("global.concepts.created-on"), value: "createdOn" },
        { text: this.$t("global.modules.module"), value: "module" },
        { text: this.$t("global.concepts.version"), value: "version" },
        {
          text: this.$t("global.concepts.status"),
          value: "status",
          width: "210px",
        },
      ],
      items: [],
      timerId: undefined,
      busy: true,
    };
  },

  computed: {},

  methods: {
    formatDate,

    continuouslyUpdateStatus() {
      this.timerId = window.setTimeout(() => {
        if (this.items?.length < 1) {
          return;
        }
        const infoId = this.items[0].infoId;

        this.getRunInfoStatus(infoId).then((res) => {
          this.items[0].status = res;
        });
        this.continuouslyUpdateStatus();
      }, 1000);
    },

    async getRunInfoStatus(runInfoId) {
      return await get("/simulationRun/run-info-status/" + runInfoId).catch(
        (error) => this.onError(error)
      );
    },

    onRowClick(item) {
      const runId = item.id;

      this.$router.push({ name: "Run", params: { runId: runId } });
    },

    async getRuns(flowTaskId) {
      return await get("/flow-task/" + flowTaskId + "/runs").catch((error) => {
        this.onError(error);
      });
    },

    onError(error) {
      this.$store.commit("showError", error);
    },

    async loadRuns() {
      this.busy = true;
      this.items = [];
      this.runs = await this.getRuns(this.flowTaskId);

      if (this.runs) {
        this.runs.forEach(async (value) => {
          const item = {};

          item.infoId = value.infoId;
          item[this.headers[0].value] = value.id;
          item[this.headers[1].value] = value.title;
          item[this.headers[2].value] = value.dateCreated;
          item[this.headers[3].value] = value.module;
          item[this.headers[4].value] = value.moduleVersion;
          item[this.headers[5].value] = value.translatedStatus;

          let statusIcon = this.$store.getters.getStatusIcon(value.status);
          item.statusColor = statusIcon.statusColor;
          item.statusIcon = statusIcon.statusIcon;

          this.items.push(item);
        });
      }
      this.busy = false;

      setTimeout(() => {
        this.$refs.bottomEl?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
  },

  watch: {
    flowTaskId: async function () {
      await this.loadRuns();
    },
  },

  async created() {
    await this.loadRuns();
    this.continuouslyUpdateStatus();
  },

  destroyed() {
    clearTimeout(this.timerId);
  },
};
</script>
