<template>
  <div class="ooliba-basic-table">
    <v-data-table
      fixed-header
      :class="`elevation-1 ${classComputed}`"
      v-model="selectedRows"
      :value="value"
      :disabled="disabled"
      :disable-pagination="noPagination"
      :headers="headersDisplayed"
      :height="heightLocal"
      :hide-default-footer="noPagination"
      :item-key="itemKey"
      :items="items"
      :items-per-page="itemsPerPageLocal"
      :loading="loading"
      :search="search"
      :show-select="showSelect"
      :single-select="singleSelect"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :must-sort="mustSort"
      dense
      :options.sync="options"
      :server-items-length="serverItemsLength"
      :footer-props="{
        itemsPerPageText: $t('global.vuetify.dataIterator.rowsPerPageText'),
        pageText: $t('global.vuetify.dataIterator.pageText'),
        itemsPerPageOptions: itemsPerPageOptions,
      }"
      @click:row="onRowClick"
      @item-selected="onItemSelect"
      @input="$emit('input', $event)"
    >
      <!-- Link all template item.<column> to our custom slot item.<column>  -->
      <template v-for="header in headers" #[`item.${header.value}`]="{ item }">
        <slot :name="`item.${header.value}`" :item="item">
          {{ item[header.value] }}
        </slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    disabled: Boolean,

    // the array of seleced rows, it's called value so it can be used with v-model
    value: Array,

    /**
     * header option (without vuetify):
     * - class: String - Header class to used (will overwrite default class ooliba-basic-table-header)
     * - hidden: Boolean - To hide the column at start
     */
    headers: Array,

    /**
     * header class to use by default
     */
    headersClassDefault: {
      type: String,
      default: "ooliba-basic-table-header",
    },

    height: [String, Number],

    itemKey: String,
    items: Array,
    itemsPerPage: {
      type: Number,
      default: 10,
    },

    itemsPerPageOptions: {
      type: Array,
      default: () => [25, 50, 100],
    },

    loading: Boolean,
    noPagination: Boolean,
    numberItemShow: {
      type: [Number, String],
      default: 200,
    },
    showSelect: Boolean,

    singleSelect: Boolean,
    selected: Object,

    sortBy: String,
    sortDesc: Boolean,
    mustSort: Boolean,

    serverItemsLength: Number,
  },

  data() {
    return {
      headersDisplayed: [],
      headersLocal: [],

      search: "",
      selectedRows: [],

      options: {},
    };
  },

  computed: {
    classComputed() {
      let classComputed = "";
      if (this.showSelect) {
        classComputed += " ooliba-basic-table-selection-mode";
      }

      if (this.noPagination) {
        classComputed += " ooliba-basic-table-no-pagination";
      }
      return classComputed;
    },

    heightLocal() {
      return this.noPagination &&
        !this.height &&
        this.items.length > Number(this.numberItemShow)
        ? 32 * Number(this.numberItemShow) + 32 // size of a dense line 32px; size of the header 32px
        : this.height;
    },

    itemsPerPageLocal() {
      return this.noPagination ? -1 : this.itemsPerPage;
    },
  },

  watch: {
    headers: {
      handler() {
        // Init local header (with props and reference init)
        this.headersLocal = this.headers.map((header) => {
          // get default header class
          let hclass = header.class ? header.class : this.headersClassDefault;

          return {
            ...header,
            class: hclass,
            show: !header.hidden, // by default all column is show
            showFilter: !!header.showFilter,
            showFilterOpt: !header.noFilterable && !header.treeFilter,
            showHideOpt: !header.noHideable,
          };
        });
      },
      immediate: true,
    },

    headersLocal: {
      deep: true,
      handler() {
        // Computed the header to display from headersLocal when changed (deep)
        this.headersDisplayed = this.headersLocal.filter((h) => h.show);
      },
      immediate: true,
    },

    items() {
      this.selectedRows = []; // Reset selection  when new items
    },

    selected() {
      this.selectedRows = [this.selected];
    },

    options: {
      handler() {
        this.$emit("options-changed", this.options);
      },
      deep: true,
    },
  },

  methods: {
    onItemSelect(selected) {
      this.$emit("item-select", selected.item);
      this.$emit("update:selected", selected.value ? selected.item : null);
    },

    onRowClick(row) {
      this.$emit("row-clicked", row);
    },
  },
};
</script>
