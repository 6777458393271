<template>
  <module-template
    id="scrolltainer"
    :title="$t('global.menu.administration.users')"
    canCreate
    :createToolTip="
      $t('global.header.newVowel', [
        $t('global.concepts.sysuser').toLowerCase(),
      ])
    "
    @newItem="createUser"
  >
    <ooliba-basic-table
      :headers="headers"
      :items="items"
      :loading="busy"
      no-pagination
      :height="tableHeight(85, items?.length)"
      @row-clicked="onRowClick"
    />
  </module-template>
</template>

<script>
import { get } from "@/model/api";
import ModuleTemplate from "@/components/layout/ModuleTemplate";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import { tableHeight } from "@/model/util";

export default {
  name: "UserList",
  components: {
    ModuleTemplate,
    OolibaBasicTable,
  },
  data() {
    return {
      headers: [
        { text: this.$t("global.concepts.name"), value: "username" },
        { text: this.$t("global.administration.email"), value: "email" },
      ],
      items: [],
      users: [],

      busy: true,
    };
  },
  methods: {
    tableHeight,

    createUser() {
      this.$router.push({ name: "Create user" });
    },
    async getUsers() {
      return await get("/user").catch((error) => {
        this.$store.commit("showError", error);
      });
    },
    onRowClick(record) {
      const userId = record.id;

      this.$router.push({ name: "User", params: { userId: userId } });
    },
  },
  async created() {
    this.users = await this.getUsers();
    if (!this.users) {
      return;
    }

    this.users.forEach((value) => {
      const item = {};

      item["id"] = value.id;
      item[this.headers[0].value] = value.username;
      item[this.headers[1].value] = value.email;

      this.items.push(item);
    });
    this.busy = false;
  },
};
</script>
