<template>
  <div>
    <ooliba-basic-table
      v-if="hasAccess"
      :headers="headers"
      :items="items"
      :loading="busy"
      no-pagination
      @row-clicked="onRowClick"
    >
      <template v-slot:[`item.available`]="{ item }">
        <v-icon small class="px-4" v-if="item.available" color="green">
          pic-check
        </v-icon>
        <v-icon small class="px-4" v-else color="red"> pic-close </v-icon>
      </template>
      <template #[`item.uploadedOn`]="{ item }">
        {{ formatDate(item.uploadedOn) }}
      </template>
    </ooliba-basic-table>
    <v-alert v-else type="error">{{
      $t("global.business-process.task-no-access")
    }}</v-alert>
    <v-dialog v-model="showUpload" width="1000">
      <upload-file
        :fileName="selectedFileInfo.name"
        :fileEnvName="fileEnv"
        nameDisabled
        envDisabled
        @upload="onUpload"
        @cancel="onCancel"
      ></upload-file>
    </v-dialog>
    <div ref="bottomEl"></div>
  </div>
</template>

<script>
import { get, postForm } from "@/model/api";
import UploadFile from "@/components/UploadFile";
import OolibaBasicTable from "@/components/OolibaBasicTable";
import { formatDate } from "@/model/util";

export default {
  props: { flowTaskId: Number, fileEnv: String },
  components: { UploadFile, OolibaBasicTable },
  name: "FileUploadTask",
  data() {
    return {
      fileInfos: [],
      selectedFileInfo: {},
      headers: [
        { text: this.$t("global.environment.env"), value: "env" },
        { text: this.$t("global.concepts.name"), value: "name" },
        { text: this.$t("global.concepts.comment"), value: "comment" },
        { text: this.$t("global.concepts.uploaded-by"), value: "uploadedBy" },
        { text: this.$t("global.concepts.uploaded-on"), value: "uploadedOn" },
        { text: this.$t("global.concepts.available"), value: "available" },
      ],
      items: [],
      hasAccess: true,
      showUpload: false,
      busy: true,
    };
  },
  methods: {
    formatDate,
    onRowClick(record) {
      if (this.$store.state.bpInstModifyPermission) {
        this.selectedFileInfo = this.fileInfos.find(
          (info) => info.id === record.id
        );
        if (!this.selectedFileInfo.fileVersion) {
          this.showUpload = true;
        }
      }
    },
    onCancel() {
      this.showUpload = false;
    },
    onUpload(data) {
      if (this.selectedFileInfo) {
        const formData = new FormData();

        formData.append("fileName", data.fileName);
        formData.append("environment", data.environment);
        formData.append("file", data.file);

        const flowInteractionId = this.selectedFileInfo.master.id;

        postForm(
          "/flow-interaction/" + flowInteractionId + "/upload-file",
          formData
        )
          .then(() => {
            this.uploaded();
          })
          .catch((error) => {
            this.$store.commit("showError", error);
          });
      }
    },
    uploaded() {
      this.showUpload = false;
      this.loadFileInfos();
    },
    async getUploadTaskFileInfos(flowTaskId) {
      return await get(
        "/flow-task/" + flowTaskId + "/upload-task-file-infos"
      ).catch((error) => {
        if (error.response.status === 403) {
          this.hasAccess = false;
        } else {
          this.$store.commit("showError", error);
        }
      });
    },
    createFileName(fileInfo) {
      var version = "";

      if (fileInfo.fileVersion)
        version = " (v." + (fileInfo.fileVersion.versionNumber + 1) + ")";

      return fileInfo.name + version;
    },

    async loadFileInfos() {
      this.busy = true;
      this.items = [];
      this.fileInfos = await this.getUploadTaskFileInfos(this.flowTaskId);

      if (this.fileInfos) {
        this.fileInfos.forEach(async (value) => {
          const item = {};

          item["id"] = value.id;
          item[this.headers[0].value] =
            value.fileVersion?.master?.fileEnv?.name;
          item[this.headers[1].value] = this.createFileName(value);
          item[this.headers[2].value] = value.fileVersion?.comment;
          item[this.headers[3].value] = value.uploadedBy?.username;
          item[this.headers[4].value] = value.uploadedOn;
          item[this.headers[5].value] = value.fileVersion ? true : false;

          this.items.push(item);
        });
      }
      this.busy = false;
      setTimeout(() => {
        this.$refs.bottomEl?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
  },
  watch: {
    flowTaskId: async function () {
      await this.loadFileInfos();
    },
  },
  async created() {
    await this.loadFileInfos();
  },
};
</script>
