import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const showMessage = (state, color, text) => {
  if (!text || (typeof text === "object" && !text.message)) {
    // if object or message null
    // dont show message when no msg
    return;
  }
  state.snackbar.color = color;
  state.snackbar.text = text;
  state.snackbar.date = new Date().getTime().toString(36);
  state.snackbar.show = true;
};

export default new Vuex.Store({
  state: {
    isLoggedIn: localStorage.rememberMe === "true",
    username: localStorage.username,
    locale: localStorage.locale ?? "en",

    runReadPermission: localStorage.runReadPermission === "true",
    runCreatePermission: localStorage.runCreatePermission === "true",
    runDeletePermission: localStorage.runDeletePermission === "true",
    runRenamePermission: localStorage.runRenamePermission === "true",

    bpInstReadPermission: localStorage.bpInstReadPermission === "true",
    bpInstCreatePermission: localStorage.bpInstCreatePermission === "true",
    bpInstDeletePermission: localStorage.bpInstDeletePermission === "true",
    bpInstModifyPermission: localStorage.bpInstModifyPermission === "true",
    bpInstRenamePermission: localStorage.bpInstRenamePermission === "true",
    bpInstLockPermission: localStorage.bpInstLockPermission === "true",

    bpDefReadPermission: localStorage.bpDefReadPermission === "true",
    bpDefCreatePermission: localStorage.bpDefCreatePermission === "true",
    bpDefDeletePermission: localStorage.bpDefDeletePermission === "true",
    bpDefModifyPermission: localStorage.bpDefModifyPermission === "true",

    processTaskReadPermission:
      localStorage.processTaskReadPermission === "true",
    processTaskCreatePermission:
      localStorage.processTaskCreatePermission === "true",
    processTaskDeletePermission:
      localStorage.processTaskDeletePermission === "true",
    processTaskModifyPermission:
      localStorage.processTaskModifyPermission === "true",

    securityPermission: localStorage.securityPermission === "true",

    importOlpPermission: localStorage.importOlpPermission === "true",

    infoPermission: localStorage.infoPermission === "true",

    fileEnvReadPermission: localStorage.fileEnvReadPermission === "true",
    fileEnvCreatePermission: localStorage.fileEnvCreatePermission === "true",
    fileEnvDeletePermission: localStorage.fileEnvDeletePermission === "true",
    fileEnvModifyPermission: localStorage.fileEnvModifyPermission === "true",

    envPropsPermission: localStorage.envPropsPermission === "true",

    releaseDeletePermission: localStorage.releaseDeletePermission === "true",

    olpExportPermission: localStorage.olpExportPermission === "true",

    repoEntityCreatePermission:
      localStorage.repoEntityCreatePermission === "true",
    fileDeletePermission: localStorage.fileDeletePermission === "true",
    logPermission: localStorage.logPermission === "true",

    snackbar: {
      color: "",
      text: "",
      date: null,
      show: false,
      timeout: 5000,
    },

    numRecentBps: 5,
    recentBps: JSON.parse(localStorage.recentBps ?? "[]"),
    recentBpsMap: JSON.parse(localStorage.recentBpsMap ?? "{}"),

    showPath: localStorage.showPath === "true" ?? false,
    showAll: localStorage.showAll === "true" ?? false,
    treeEnabled: localStorage.treeEnabled === "true" ?? false,
    itemsPerPage: localStorage.itemsPerPage ?? 25,
  },

  getters: {
    getStatusIcon: () => (status) => {
      let item = {};
      if (
        status === "COMPUTING" ||
        status === "SCHEDULED" ||
        status === "INITIALIZING" ||
        status === "FINISHING" ||
        status === "FINALIZING"
      ) {
        item.statusIcon = "RUNNING";
      } else if (status === "SUCCESS") {
        item.statusColor = "green";
        item.statusIcon = "mdi-check";
      } else if (
        status === "INIT_ERROR" ||
        status === "ERROR" ||
        status === "REJECTED_DATA"
      ) {
        item.statusColor = "red";
        item.statusIcon = "mdi-close";
      } else if (status === "CANCELLED") {
        item.statusColor = "orange";
        item.statusIcon = "mdi-cancel";
      } else if (
        status === "DEFINING" ||
        status === "IDLE" ||
        status === "WAITING_FOR_CONFIG" ||
        status === "WAITING_FOR_EVALUATION" ||
        status === "WAITING_FOR_DATA" ||
        status === "INVALID_DATA" ||
        status === "INIT"
      ) {
        item.statusColor = "blue";
        item.statusIcon = "mdi-timer-sand";
      } else if (status === "READY_TO_START") {
        item.statusColor = "light-blue";
        item.statusIcon = "mdi-timer-play";
      }
      return item;
    },

    isStatusRunning: () => (status) => {
      return (
        status !== "CANCELLED" &&
        status !== "DEFINING" &&
        status !== "ERROR" &&
        status !== "IDLE" &&
        status !== "INIT_ERROR" &&
        status !== "INIT" &&
        status !== "INVALID_DATA" &&
        status !== "READY_TO_START" &&
        status !== "REJECTED_DATA" &&
        status !== "SUCCESS" &&
        status !== "WAITING_FOR_DATA" &&
        status !== "WAITING_FOR_EVALUATION" &&
        status !== "WAITING_FOR_CONFIG"
      );
    },

    isStatusPreRun: () => (status) => {
      return (
        status == "INIT" ||
        status == "READY_TO_START" ||
        status == "WAITING_FOR_DATA" ||
        status == "WAITING_FOR_EVALUATION" ||
        status == "REJECTED_DATA" ||
        status == "INVALID_DATA"
      );
    },

    isStatusFinished: () => (status) => {
      return (
        status == "CANCELLED" ||
        status == "SUCCESS" ||
        status == "ERROR" ||
        status == "INIT_ERROR"
      );
    },

    isStatusReady: () => (status) => {
      return status === "READY_TO_START";
    },

    isStatusError: () => (status) => {
      return (
        status === "ERROR" ||
        status === "INIT_ERROR" ||
        status === "INVALID_DATA" ||
        status === "REJECTED_DATA"
      );
    },

    isShowPath: (state) => {
      return state.showPath;
    },

    isShowAll: (state) => {
      return state.showAll;
    },

    isTreeEnabled: (state) => {
      return state.treeEnabled;
    },

    getItemsPerPage: (state) => {
      return state.itemsPerPage;
    },
  },

  mutations: {
    setUsername(state, username) {
      state.username = username;
    },
    setLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },

    setRunReadPermission(state, runReadPermission) {
      state.runReadPermission = runReadPermission;
    },
    setRunCreatePermission(state, runCreatePermission) {
      state.runCreatePermission = runCreatePermission;
    },

    setRunDeletePermission(state, runDeletePermission) {
      state.runDeletePermission = runDeletePermission;
    },

    setRunRenamePermission(state, runRenamePermission) {
      state.runRenamePermission = runRenamePermission;
    },

    setBpInstReadPermission(state, bpInstReadPermission) {
      state.bpInstReadPermission = bpInstReadPermission;
    },
    setBpInstCreatePermission(state, bpInstCreatePermission) {
      state.bpInstCreatePermission = bpInstCreatePermission;
    },
    setBpInstDeletePermission(state, bpInstDeletePermission) {
      state.bpInstDeletePermission = bpInstDeletePermission;
    },

    setBpInstModifyPermission(state, bpInstModifyPermission) {
      state.bpInstModifyPermission = bpInstModifyPermission;
    },

    setBpInstRenamePermission(state, bpInstRenamePermission) {
      state.bpInstRenamePermission = bpInstRenamePermission;
    },

    setBpInstLockPermission(state, bpInstLockPermission) {
      state.bpInstLockPermission = bpInstLockPermission;
    },

    setBpDefReadPermission(state, bpDefReadPermission) {
      state.bpDefReadPermission = bpDefReadPermission;
    },
    setBpDefCreatePermission(state, bpDefCreatePermission) {
      state.bpDefCreatePermission = bpDefCreatePermission;
    },
    setBpDefDeletePermission(state, bpDefDeletePermission) {
      state.bpDefDeletePermission = bpDefDeletePermission;
    },
    setBpDefModifyPermission(state, bpDefModifyPermission) {
      state.bpDefModifyPermission = bpDefModifyPermission;
    },

    setProcessTaskReadPermission(state, processTaskReadPermission) {
      state.processTaskReadPermission = processTaskReadPermission;
    },
    setProcessTaskCreatePermission(state, processTaskCreatePermission) {
      state.processTaskCreatePermission = processTaskCreatePermission;
    },
    setProcessTaskDeletePermission(state, processTaskDeletePermission) {
      state.processTaskDeletePermission = processTaskDeletePermission;
    },
    setProcessTaskModifyPermission(state, processTaskModifyPermission) {
      state.processTaskModifyPermission = processTaskModifyPermission;
    },

    setSecurityPermission(state, securityPermission) {
      state.securityPermission = securityPermission;
    },

    setImportOlpPermission(state, importOlpPermission) {
      state.importOlpPermission = importOlpPermission;
    },

    setInfoPermission(state, infoPermission) {
      state.infoPermission = infoPermission;
    },

    setFileEnvReadPermission(state, fileEnvReadPermission) {
      state.fileEnvReadPermission = fileEnvReadPermission;
    },
    setFileEnvCreatePermission(state, fileEnvCreatePermission) {
      state.fileEnvCreatePermission = fileEnvCreatePermission;
    },
    setFileEnvDeletePermission(state, fileEnvDeletePermission) {
      state.fileEnvDeletePermission = fileEnvDeletePermission;
    },
    setFileEnvModifyPermission(state, fileEnvModifyPermission) {
      state.fileEnvModifyPermission = fileEnvModifyPermission;
    },
    setEnvPropsPermission(state, envPropsPermission) {
      state.envPropsPermission = envPropsPermission;
    },
    setReleaseDeletePermission(state, permission) {
      state.releaseDeletePermission = permission;
    },

    setOlpExportPermission(state, permission) {
      state.olpExportPermission = permission;
    },

    setRepoEntityCreatePermission(state, permission) {
      state.repoEntityCreatePermission = permission;
    },

    setFileDeletePermission(state, permission) {
      state.fileDeletePermission = permission;
    },

    setLogPermission(state, permission) {
      state.logPermission = permission;
    },

    clear(state) {
      localStorage.clear();
      state.isLoggedIn = false;
      state.username = "";

      state.runReadPermission = false;
      state.runCreatePermission = false;
      state.runDeletePermission = false;
      state.runRenamePermission = false;

      state.bpInstReadPermission = false;
      state.bpInstCreatePermission = false;
      state.bpInstDeletePermission = false;
      state.bpInstModifyPermission = false;
      state.bpInstRenamePermission = false;
      state.bpInstLockPermission = false;

      state.bpDefReadPermission = false;
      state.bpDefCreatePermission = false;
      state.bpDefDeletePermission = false;
      state.bpDefModifyPermission = false;

      state.processTaskReadPermission = false;
      state.processTaskCreatePermission = false;

      state.securityPermission = false;

      state.importOlpPermission = false;

      state.infoPermission = false;

      state.fileEnvReadPermission = false;
      state.fileEnvCreatePermission = false;
      state.fileEnvDeletePermission = false;
      state.fileEnvModifyPermission = false;

      state.envPropsPermission = false;

      state.releaseDeletePermission = false;

      state.olpExportPermission = false;

      state.repoEntityCreatePermission = false;
      state.fileDeletePermission = false;
      state.logPermission = false;
    },

    addToRecentBps(state, { id, name }) {
      // the "+" makes sure idNumber is a number, this is necessary because id is sometimes a string and sometimes a number, which causes a problem when we call the indexOf method
      const idNumber = +id;
      if (!state.recentBpsMap[idNumber]) {
        if (state.recentBps.length == state.numRecentBps) {
          const lastId = state.recentBps.pop();
          delete state.recentBpsMap[lastId];
        }
      } else {
        state.recentBps.splice(state.recentBps.indexOf(idNumber), 1);
      }
      state.recentBps.unshift(idNumber);
      state.recentBpsMap[idNumber] = name;
      localStorage.recentBps = JSON.stringify(state.recentBps);
      localStorage.recentBpsMap = JSON.stringify(state.recentBpsMap);
    },

    removeFromRecentBps(state, id) {
      // the "+" makes sure idNumber is a number, this is necessary because id is sometimes a string and sometimes a number, which causes a problem when we call the indexOf method
      const idNumber = +id;
      state.recentBps.splice(state.recentBps.indexOf(idNumber), 1);
      delete state.recentBpsMap[idNumber];
      localStorage.recentBps = JSON.stringify(state.recentBps);
      localStorage.recentBpsMap = JSON.stringify(state.recentBpsMap);
    },

    showError(state, error) {
      if (error.status === 403) {
        // don't show error when the user is not logged in
        return;
      }
      if (error.message) {
        const errorMessage =
          error.response?.data?.title ?? error.response?.data;

        showMessage(state, "error", error.message + ": " + errorMessage);
      } else if (error.detail) {
        showMessage(state, "error", error.detail);
      } else if (error.error) {
        showMessage(state, "error", error.error);
      } else {
        showMessage(state, "error", error);
      }
    },

    showSuccess(state, text) {
      showMessage(state, "success", text);
    },

    setShowPath(state, showPath) {
      localStorage.showPath = showPath;
      state.showPath = showPath;
    },

    setShowAll(state, showAll) {
      localStorage.showAll = showAll;
      state.showAll = showAll;
    },

    setTreeEnabled(state, treeEnabled) {
      localStorage.treeEnabled = treeEnabled;
      state.treeEnabled = treeEnabled;
    },

    setItemsPerPage(state, itemsPerPage) {
      localStorage.itemsPerPage = itemsPerPage;
      state.itemsPerPage = itemsPerPage;
    },
  },
  actions: {},
  modules: {},
});
